.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px; 
  background-color: black;
  color: white;
}
